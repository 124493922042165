import React from 'react';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import './App.less';
import './App.scss';
import AppLayout from 'containers/AppLayout';
// import Page403 from 'containers/shared/Page403';
// import Page404 from 'containers/shared/Page404';
// import Page500 from 'containers/shared/Page500';

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<AppLayout />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
