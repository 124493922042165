import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Layout } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { IRoute } from 'interfaces';

const { Content } = Layout;

interface AppContentProps {
  filteredRoutes: IRoute[];
}

const AppContent: React.FC<AppContentProps> = props => {
  const { filteredRoutes } = props;

  return (
    <Content className="app-content">
      <Suspense fallback={null}>
        <Routes>
          {filteredRoutes.map(({ component: Component, ...rest }) => {
            return <Route {...rest} key={uuidv4()} element={<Component />} />;
          })}
          <Route path="/" element={<Navigate to="/404" />} />
        </Routes>
      </Suspense>
    </Content>
  );
};

export default AppContent;
